import { Stack, Text } from '@chakra-ui/react';
import LDLink from 'components/common/LDLink';
import React from 'react';
import { URLS } from 'utils/constants';

const ExpiryWarning = ({
  firstName,
  rewardTimeToLive
}: {
  firstName: string;
  rewardTimeToLive: string;
}) => {
  return (
    <>
      <Stack
        width="100%"
        direction="row"
        justifyContent="center"
        alignItems="center"
        backgroundColor="extras.banner"
        // height={{ base: '100px', md: '80px', lg: '50px' }}
        marginY={2}
        padding="10px"
      >
        <Text
          fontSize={{ base: 'sm', lg: 'md' }}
          color="white"
          width={{ base: '100%', lg: '57%' }}
          align="center"
          fontWeight="semibold"
        >
          {firstName}, you've worked hard to earn your LDExtras points which are nearing expiration.
          You have options to maintain your points; make any eligible purchase at London Drugs or{" "}
          <LDLink href={'https://www.londondrugs.com'} textDecoration="underline">londondrugs.com</LDLink> , or redeem a reward
          in the <LDLink href={URLS.MY_EXTRAS_REWARDS} textDecoration="underline">"Rewards & Vouchers"</LDLink> section before:{' '}
          {rewardTimeToLive}.
        </Text>
      </Stack>
    </>
  );
};

export default ExpiryWarning;
