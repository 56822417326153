import { NextPageContext } from 'next';
import { FetchMethod, UnknownObjectAny } from './global.types';

export type ActiveStatus = 'A' | 'I' | 'N/A';
export type AccountStatus = ActiveStatus;
export type RegistrationStatus = 'R' | 'U' | 'ALL';
export type DeliverabilityStatus = 'G' | 'N';

export interface ExtrasRequestParameters {
  endpoint: string;
  errorMessage: string;
  opts: RequestOptions;
}

export interface RequestOptions {
  method: FetchMethod;
  body?: BodyInit | null | undefined;
  headers: { authorization: string; 'content-type'?: string };
}
export interface KobieResponse extends UnknownObjectAny {
  success: boolean;
  error?: string | unknown;
  error_description?: string;
  logout?: boolean;
}

export type ExtrasResponse = KobieResponse | undefined | null;

export interface ClientProperty {
  key: 'string';
  value: 'string';
}

export type ClientProperties = Array<ClientProperty>;

export interface Member {
  genderCode: string;
  addresses: Array<{ addressId: number }>;
}
export interface MemberSummary {
  firstName: string;
  lastName: string;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  stateProvinceCode: string | null;
  postalCode: string | null;
  countryCode: string | null;
  phoneNumber: string;
  accountStatus: AccountStatus;
  emailAddress: string;
  cardLastFour: string;
  ytdVisits: number;
  lifeVisits: number;
  balance: number;
  pending: number;
  userName: string;
  canEarn: boolean;
  canRedeem: boolean;
  rewardMonthsToLive: number;
  accountId: number;
  customerUUID: string;
  dateOfBirth: string | null;
  enrollDate: string;
  tierCode: string;
  success?: boolean;
  emailDeliverabilityCode: DeliverabilityStatus | null;
  phoneDeliverabilityCode: DeliverabilityStatus | null;
  rewardTimeToLive: string | null;
}

export interface RewardsSummary {
  baseRewardsAvailable: number;
  bonusRewardsAvailable: number;
  totalRewardsAvailable: number;
  totalRewardsPending: number;
  rewardsEarnedLifetime: number;
  rewardsExpiredLifetime: number;
  rewardsRedeemedLifetime: number;
  rewardType: string;
  rewardTypeDescription: string;
  ytdTierQualifyingValue: number;
  conversionPointsPreviouslyRedeemed: number;
  monthlyRewardsSummary: Array<{
    year: string;
    month: string;
    earnedAmount: number;
  }>;
  monthlyRewardsToExpire: Array<{
    year: string;
    month: string;
    rewardsAmountToExpire: number;
  }>;
}

export interface CertificateSummary {
  expiredTotal: number;
  availableTotal: number;
  lifetimeTotal: number;
  rewardsToNextCertificate: number;
  currencyCode: string;
  rewardType: string;
  rewardTypeDescription: string;
  autoRedeemInd: boolean;
  certsAvailableCount: number;
}

export interface Certificate extends UnknownObjectAny {}

export interface Certificates {
  certificates: Array<Certificate>;
  success: boolean;
}

export interface Reward {
  amount: string;
  bonusCode: string;
  bonusType: string;
  bonusDescription: string;
  rewardId: number;
  activeDate: Date;
  expireDate?: Date;
  tqv: number;
  canViewDetail: boolean;
  transId?: number | null;
  xrefId?: null;
  redemptionId?: number | null;
}

export interface Rewards {
  rewards: Array<Reward>;
  success?: boolean;
}

export type CheckboxValue = boolean | 0 | 1;

export interface InterestsFormObject {
  [key: string]: CheckboxValue;
  beauty: CheckboxValue;
  food: CheckboxValue;
  health: CheckboxValue;
  home: CheckboxValue;
  mother: CheckboxValue;
  natural: CheckboxValue;
  photography: CheckboxValue;
  technology: CheckboxValue;
  toys: CheckboxValue;
  travel: CheckboxValue;
}

export interface MemberBrief {
  tierExpireDate: string | null;
}

export interface TierSummary {
  tierCode: 'TIER1' | 'TIER2';
  tierDescription: string;
  tierEnrollDate: Date;
  higherTiers?: Array<{
    tierCode: 'TIER2';
    tierDescription: string;
    spendReq: number;
  }>;
}

export interface ProductFilters {
  payload: {
    criteria: {
      redeemSegmentCode: string;
      cardProductCode: string;
    };
  };
}

export interface Product {
  name: string;
  description: string;
  brand?: string | null;
  productTerms: string | null;
  id: number;
  code: string;
  minAmount: number;
  maxAmount: number;
  // Not sure what the images data will look like - will need to update this
  images: null;
  categories: Array<{ code?: string; category?: string }>;
}

export interface Products {
  products?: Array<Product> | null;
  success?: boolean;
}

export interface ProductDetails {
  productDetails?: {
    code: string;
    items: Array<{ inventoryItemId: number }>;
  };
  success?: boolean;
}

export interface OfferFilters {
  payload: {
    startDate?: Date;
    endDate?: Date;
    offerCode?: string;
    registrationStatus: RegistrationStatus;
    status: ActiveStatus;
  };
}

export interface Offer {
  accountOfferId: number;
  offerCode: string;
  description: string;
  offerTerms: string;
  status: ActiveStatus;
  expiresDate?: Date | null;
  offerLink?: string;
}

export interface Offers {
  offers: Array<Offer>;
  success: boolean;
}

export interface Coupon {
  accountOfferId: number;
  offerCode: string;
  description: string;
  offerTerms: string;
  status: ActiveStatus;
  expiresDate?: Date | null;
  offerLink?: string;
  couponCode?: string;
  unlimitedUseInd?: false;
  availableUses?: number;
  accountCouponIssueStartDate?: Date | null;
  accountCouponIssueExpireDate?: Date | null;
  accountId?: number;
  customerId?: number;
  couponIssueId?: number;
  couponTypeCode?: string;
  couponTypeDescription?: string;
  allowableUses?: number;
  couponValidStartDate?: Date | null;
  couponValidEndDate?: Date | null;
  programCode?: string;
  campaignCode?: string;
  promotionCode?: string;
  offerDescription?: string;
  couponUpdateDate?: Date | null;
  couponUsages?: null;
}

export interface Coupons {
  coupons: Array<Coupon>;
  success: boolean;
}

export interface OffersBody {
  payload: {
    updateAccountOfferRegistrationDate: {
      registrationDate: Date;
    };
  };
}

export interface Redemption {
  inventoryItemId: number;
  amount: number;
  quantity: number;
  redemptionDetailId: number;
}
export interface RedemptionResponse {
  redemption: {
    redemptionId: number;
    items: Array<Redemption>;
    totalAmount: number;
  };
}

export interface MemberPreference {
  preferenceCode: string;
  description: string;
  status: ActiveStatus;
}

export type MemberPreferences = Array<MemberPreference>;

export interface InterestsResponse {
  memberPreference: MemberPreferences;
}

export interface InterestsDataObject {
  payload: {
    memberPreference: MemberPreferences;
  };
}

export interface PasswordUpdateDataObject {
  payload: {
    updatePassword: {
      currentPassword: string;
      password: string;
    };
  };
}

export interface PasswordResetDataObject {
  payload: {
    resetPassword: {
      username: string;
    };
  };
}

export interface Transaction {
  rewardsTotal: string;
  transId: number;
  transTypeCode: string;
  transTypeDescription: string;
}

export interface Transactions {
  success: boolean;
  transactions: Array<Transaction>;
}

export interface Redemption {
  redemptionId: number;
  rewardAmount: string;
  redemptionItemInfoDTOList: Array<{ quantity: number; itemDescription: string }>;
}

export interface Redemptions {
  success: boolean;
  redemptions: Array<Redemption>;
}

export interface CommunicationPreferencesBody {
  payload: {
    communicationPreferences: Array<{ communicationId: number; optedInInd: boolean }>;
  };
}

export interface MemberSearchBody {
  payload: {
    criteria: {
      emailAddress: string;
    };
  };
}

// type check objects to make sure they contain a successful api response
export const isMember = (obj: Member | ExtrasResponse): obj is Member =>
  obj?.genderCode !== undefined;
export const isMemberSummary = (obj: MemberSummary | ExtrasResponse): obj is MemberSummary =>
  obj?.firstName !== undefined;
export const isRewards = (obj: Rewards | ExtrasResponse): obj is Rewards =>
  obj?.rewards !== undefined;
export const isRewardsSummary = (obj: RewardsSummary | ExtrasResponse): obj is RewardsSummary =>
  obj?.totalRewardsAvailable !== undefined;
export const isCertificates = (obj: Certificates | ExtrasResponse): obj is Certificates =>
  obj?.certificates !== undefined;
export const isCoupons = (obj: Coupons | ExtrasResponse): obj is Coupons =>
  obj?.coupons !== undefined;
export const isCertificateSummary = (
  obj: CertificateSummary | ExtrasResponse
): obj is CertificateSummary => obj?.availableTotal !== undefined;
export const isMemberBrief = (obj: MemberBrief | ExtrasResponse): obj is MemberBrief =>
  obj?.tierExpireDate !== undefined;
export const isTierSummary = (obj: TierSummary | ExtrasResponse): obj is TierSummary =>
  !!obj?.tierCode;
export const isOffers = (obj: Offers | ExtrasResponse): obj is Offers => obj?.offers !== undefined;
export const isProductDetail = (obj: ProductDetails | ExtrasResponse): obj is ProductDetails =>
  !!obj?.productDetails;
export const isInterests = (obj: InterestsResponse | ExtrasResponse): obj is InterestsResponse =>
  !!obj?.memberPreference;
