import { MobxMemberSummary } from 'models/extras.model';
import { Member, MemberSummary } from 'types/extras';

export const transformMemberSummary = (
  summary: MemberSummary,
  member: Member
): MobxMemberSummary => {
  const { genderCode, addresses } = member;
  const {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    cardLastFour,
    balance,
    pending,
    ytdVisits,
    lifeVisits,
    addressLine1,
    addressLine2,
    city,
    stateProvinceCode,
    postalCode,
    countryCode,
    canEarn,
    canRedeem,
    userName,
    enrollDate,
    tierCode,
    dateOfBirth,
    emailDeliverabilityCode,
    phoneDeliverabilityCode,
    rewardTimeToLive
  } = summary;
  return {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    userName,
    enrollDate,
    dateOfBirth,
    emailDeliverabilityCode,
    phoneDeliverabilityCode,
    genderCode,
    pointsInfo: {
      cardLastFour,
      balance,
      pending,
      ytdVisits,
      lifeVisits,
      canEarn,
      canRedeem,
      tierCode,
      tierExpireDate: null,
      rewardTimeToLive
    },
    address: {
      addressId: addresses?.[0]?.addressId || 0,
      addressLine1,
      addressLine2,
      city,
      stateProvinceCode,
      postalCode,
      countryCode
    }
  };
};
